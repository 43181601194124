import "./App.css";
import { Helmet } from "react-helmet";
import Nav from "./Components/Nav/Nav";
import Carrusel from "./Components/Slider/Slider";
import About from "./Components/About/About";
import Works from "./Components/Works/Works";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import WhatsAppButton from "./Components/WhatsAppBtn/WhatsAppButton"
import GoogleReviews from "./Components/GoogleReviews/GoogleReviews"

function App() {
  return (
    <div className="App">
      <Helmet>
        <script
          src="https://alwingulla.com/88/tag.min.js"
          data-zone="111804"
          async
          data-cfasync="false"
        ></script>
      </Helmet>
      <Nav />
      <Carrusel />
      <About />
      <Works />
      <GoogleReviews />
      <Contact />
      <Footer />
      < WhatsAppButton />
    </div>
  );
}

export default App;
